<template>
  <div class="page-wrapper">
    <div class="d-flex align-center pa-4">
      <h1 class="title">
        {{ $t('suppliers') }}
      </h1>
      <v-spacer />
      <BasePrimaryActionButton
        :label="$t('create_supplier')"
        @click="$router.push({ name: 'createSupplier' })"
      />
    </div>

    <BaseTableLoader :loading="!suppliers">
      <SuppliersTable
        :items="suppliers"
        :loading="$store.getters.loading['get:api/suppliers']"
        @delete="deleteSupplier"
        @edit="$router.push({ name: 'editSupplier', params: { id: $event.id } })"
      />
    </BaseTableLoader>
    <router-view />
  </div>
</template>

<script>
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import { mapActions, mapState } from 'vuex';
import BaseTableLoader from '@/components/base/BaseTableLoader';
import SuppliersTable from '@/components/SuppliersTable';

export default {
  name: 'Suppliers',

  components: { SuppliersTable, BaseTableLoader, BasePrimaryActionButton },

  computed: mapState('suppliers', ['suppliers', 'suppliersFilterParams']),

  created() {
    this.fetchSuppliers(this.$route.query);
  },

  beforeRouteUpdate(to, from, next) {
    if (
      JSON.stringify(this.suppliersFilterParams) !== JSON.stringify(to.query) &&
      to.name === 'suppliers'
    ) {
      this.fetchSuppliers(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('suppliers', ['fetchSuppliers', 'deleteSupplier']),
  },
};
</script>

<style scoped></style>
