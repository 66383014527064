<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="displayedItems"
    :mobile-breakpoint="0"
    :loading="loading"
    :class="{ 'content-loading': loading }"
    class="overflow-auto"
    scrollable
    disable-pagination
    hide-default-footer
    disable-sort
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.responsible_for="{ item }">
      {{ $t(item.responsible_for) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :item="item"
        :loading="$store.getters.loading[`delete:api/suppliers/${item.id}`]"
      />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.responsible_for>
          {{ $t(item.responsible_for) }}
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';

export default {
  name: 'SuppliersTable',

  components: { BaseExpandedTableRow, BaseActionMenu },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
        },
        {
          text: this.$t('email'),
          value: 'email',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('responsible_for'),
          value: 'responsible_for',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    displayedItems() {
      if (!this.items) {
        return [];
      }
      return this.items.map((r) => ({
        ...r,
      }));
    },
  },

  methods: {
    getRowActions() {
      const actions = [
        {
          callback: (s) => this.$emit('edit', s),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: (s) => this.$emit('delete', s),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];
      return actions;
    },
  },
};
</script>

<style scoped></style>
